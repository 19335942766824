import { useCallback, useEffect, useMemo } from 'react';
import { useConfig } from '@kega/sps-core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { InstantSearch, Configure, Pagination, HierarchicalMenu } from 'react-instantsearch-dom';

import qs from 'qs';
import algoliasearch from 'algoliasearch/lite';

import { Col, Row, Container } from '../../components/grid';
import { t } from '../../lib/translations';
import { filterVisibleFacets } from "./utils";

import useUi from '../../hooks/useUi';
import useMediaQuery from '../../hooks/useMediaQuery';
import useNavigationPath from '../../hooks/useNavigationPath';
import useUserToken from '../../hooks/useUserToken';
import useScarabQueue from '../../hooks/useScarabQueue';

import Button from '../../components/buttons/Button';
import ReadMore from '../../components/readmore/ReadMore';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';

import Filters from './filters/Filters';
import MobileFilters from './filters/MobileFilters';
import ProductGrid from './productgrid/ProductGrid';
import ScrollTo from './scrollto/ScrollTo';
import Stats from './stats/Stats';
import SortBy from './sortby/SortBy';
import StructuredData from './structureddata/StructuredData';

import classes from './ProductList.module.css';
import CategoryHierarchy from "./categoryhierarchy/CategoryHierarchy";


const urlToSearchState = ({ search }) => {
    const { sortBy = null, hitsPerPage = null, page = 1, q = null, hierarchicalMenu, menu, toggle, ...params } = qs.parse(search.replace('?', ''), { comma: true });

    const refinementList = {};
    const range = {};

    Object.keys(params).forEach((key) => {
        const param = params[key];
        if (param?.range) {
            const [min, max] = param.range.split('-');

            range[key] = {
                min: (min === '' ? undefined : min),
                max: (max === '' ? undefined : max),
            }
        } else {
            refinementList[key] = param;
        }

    });

    let state = {
        refinementList: refinementList,
        range: range
    }

    if (range) { state.range = range; }
    if (sortBy) { state.sortBy = sortBy; }
    if (hitsPerPage) { state.hitsPerPage = hitsPerPage; }
    if (page > 1) { state.page = page; }
    if (q) { state.query = q; }

    if (hierarchicalMenu) { state.hierarchicalMenu = hierarchicalMenu }
    if (menu) { state.menu = menu }
    if (toggle) { state.toggle = toggle }

    return state;
};

const searchStateToUrl = (searchState) => {
    const { sortBy = null, hitsPerPage = null, page = 1, query, refinementList = {}, range = {}, hierarchicalMenu = {}, menu = {}, toggle = {} } = searchState;

    let searchParams = Object.keys(refinementList).reduce((params, key) => {
        if (refinementList[key]) {
            params[key] = refinementList[key]
        }
        return params;
    }, {});

    searchParams = Object.keys(range).reduce((params, key) => {
        if (range[key]) {
            const { min, max } = range[key];
            if (min !== undefined || max !== undefined) {
                params[key] = {
                    range: (min !== undefined ? min : '') + '-' + (max !== undefined ? max : '')
                }
            }

        }
        return params;
    }, searchParams);

    searchParams = Object.keys(hierarchicalMenu).reduce((params, key) => {
        if (hierarchicalMenu[key]) {
            params['hierarchicalMenu'] = hierarchicalMenu
        }
        return params;
    }, searchParams);

    searchParams = Object.keys(menu).reduce((params, key) => {
        if (menu[key]) {
            params['menu'] = menu
        }
        return params;
    }, searchParams);

    searchParams = Object.keys(toggle).reduce((params, key) => {
        if (toggle[key]) {
            params['toggle'] = toggle
        }
        return params;
    }, searchParams);

    if (sortBy) { searchParams['sortBy'] = sortBy; }
    if (hitsPerPage) { searchParams['hitsPerPage'] = hitsPerPage; }
    if (page > 1) { searchParams['page'] = page; }
    if (query) { searchParams['q'] = query; }

    return '?' + qs.stringify(searchParams, { arrayFormat: 'comma', encodeValuesOnly: true });
}

const AlgoliaProductList = ({ title = '', description = '', filters, query, level = 0, url, useAlgoliaCategoryWidget = false }) => {
    const config = useConfig();
    const navigate = useNavigate();
    const { search } = useLocation();
    const { match: desktop } = useMediaQuery('(min-width: 768px)');
    const { get } = useUserToken();
    const { pushScarabCategory } = useScarabQueue(); 

    const { category_key } = useParams();
    const path = useNavigationPath('HS_AssortmentNavBarComponent', category_key);
    const user = get();

    const { openFilters } = useUi();

    const storeCode = config.get('storeCode');
    const { index, appId, apiKey } = config.get('algolia');
    const { pagination: { defaultPerPage }, sort } = config.get('productlist');

    const searchClient = useMemo(() => algoliasearch(appId, apiKey), [appId, apiKey]);

    const searchState = useMemo(() => {
        return urlToSearchState({ search: search });
    }, [search])

    const handleChangeUrl = (url) => {
        let transformedUrl = url.replace(/\//g, ' > ');

        transformedUrl = transformedUrl.replace(/^ > c > \d+ > /, '').trim();
        transformedUrl = transformedUrl.split(' > ').map((part) =>
            part.charAt(0).toUpperCase() + part.slice(1)
        ).join(' > ');
        transformedUrl = transformedUrl.split(' > ').map((url) => url.replace(/-/g, ' ')).join('>');
        transformedUrl = transformedUrl.replace(/Non Food/i, 'Non-food');

        return transformedUrl;
    };



    useEffect(() => {
        if (url) {
            const transformedUrl = handleChangeUrl(url);
            pushScarabCategory(transformedUrl);
        }
    }, [url]);

    const onSearchStateChange = (nextSearchState) => {
        navigate({
            pathname: location.pathname,
            search: searchStateToUrl(nextSearchState),
        }, { replace: useAlgoliaCategoryWidget ? false : true });
    };

    const transformItems = useCallback((items, metadata) => {
        return filterVisibleFacets(items, metadata);
    }, [ filterVisibleFacets ]);

    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={index + '-product-' + storeCode}
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
        >

            <Configure
                query={query}
                filters={filters}
                hitsPerPage={defaultPerPage}
                clickAnalytics={true}
                enablePersonalization={true}
                userToken={'user-' + user}
            />

            <Container xl margins g-xs={2} g-md={3}>
                <Row>
                    <Col>
                        <Breadcrumb prefix={[{ name: 'Home', url_key: '/' }]} paths={path.slice(1)} /> {/* remove non food category */}
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        {
                            desktop
                                ?
                                <>
                                    {
                                        useAlgoliaCategoryWidget ?
                                            <>
                                                <span className={classes.categories_title}>{t('productlist.filter.categories')}</span>
                                                <HierarchicalMenu
                                                    attributes={[
                                                        'categoryHierarchy.lvl1',
                                                        'categoryHierarchy.lvl2',
                                                        'categoryHierarchy.lvl3',
                                                        'categoryHierarchy.lvl4',
                                                        'categoryHierarchy.lvl5',
                                                        'categoryHierarchy.lvl6',
                                                    ]} />
                                            </>
                                            :
                                            <CategoryHierarchy categoryId={category_key} />
                                    }
                                    <Filters maxValuesPerFacet={500} params={{ level: (level + 1) }} />
                                </>
                                :
                                <MobileFilters maxValuesPerFacet={500} params={{ level: (level+1) }} />
                        }
                    </Col>
                    <Col md={9}>

                        <div className={classes.category}>
                            <h1>{title}</h1>
                        </div>

                        <div className={classes.toolbar}>

                            <div className={classes.toolbar_left}>
                                <Stats className={classes.stats} />
                            </div>

                            <div className={classes.toolbar_right}>

                                {
                                    !desktop &&
                                    <Button variant="outline" className={classes.toggle_filter} onClick={openFilters}>
                                        {t('productlist.filter.mobile_button')}
                                    </Button>
                                }

                                <SortBy
                                    className={classes.sort}
                                    value={searchState.sortBy}
                                    defaultRefinement={index + '-product-' + storeCode}
                                    items={sort.map(({ key }) => {
                                        return { value: index + '-product-' + storeCode + key, label: t('productlist.sorting.' + (key === '' ? 'default' : key)) }
                                    })}
                                />

                            </div>

                        </div>

                        <ScrollTo scrollOn="page">
                            <ProductGrid />
                        </ScrollTo>

                        <div className={classes.pagination}>
                            <Pagination
                                showFirst={true}
                                showLast={true}
                                translations={{
                                    next: 'Volgende',
                                    previous: 'Vorige'
                                }}
                            />
                        </div>

                        <div className={classes.category}>
                            <ReadMore height={110} mobileOnly={true}>
                                {
                                    description !== ''
                                    &&
                                    <div dangerouslySetInnerHTML={{ __html: description }} className={classes.desc} />
                                }
                            </ReadMore>
                        </div>

                    </Col>
                </Row>
            </Container>
            <StructuredData title={title} />

        </InstantSearch>
    )
}

export default AlgoliaProductList;