import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useConfig } from '@kega/sps-core';
import { ProductImage, Price } from '@kega/sps-components';

import aa from 'search-insights';

import { t } from '../../lib/translations';

import useUserToken from '../../hooks/useUserToken';
import useTagManager from '../../hooks/useTagManager';

import AddToCart from '../../modules/addtocart/AddToCart';

import { CheckRoundIcon } from '../icons';
import Modal from '../modal/Modal';
import AddToCartSmall from '../buttons/AddToCartSmall';
import WishlistButton from "../buttons/WishlistButton";
import Average from '../reviews/average/Average';

import ProductLabels from './labels/ProductLabels';

import classes from './ProductTile.module.css';

const ProductTile = ({ product, index = null, hideAddToCart = false, hideReviews = false }) => {

    const [modalOpen, setModalOpen] = useState(false);

    const { get } = useUserToken();
    const { ecommerceEvent } = useTagManager();

    const user = get();

    const config = useConfig();
    const storeCode = config.get('storeCode');
    const currency = config.get('currency');
    const locale = config.get('locale');
    const { prefix_product } = config.get('url');

    const { index: algoliaIndex } = config.get('algolia');

    const {
        name,
        images = [],
        Merk = '',
        stock = null,
        price = null,
        priceRange = null,
        descriptionHs = '',
        baseCode,
        code,
        orderableAums = [],
        Prijs,
        strikeThroughPrice=null,
        trustPilotProductOneStar,
        trustPilotProductTwoStars,
        trustPilotProductThreeStars,
        trustPilotProductFourStars,
        trustPilotProductFiveStars,
        trustPilotProductReviewAverage,
        trustPilotProductReviewCount
    } = product;
 
    const url = product?.url;
    const parts = url?.split("/");
    const slug = parts !== undefined ? parts[parts?.length - 1] : "";

    const productPrice = typeof product?.Prijs === 'number' ? product?.Prijs : typeof Prijs?.value === 'number' ? Prijs?.value : 0;
    const promotion = Prijs?.promotion || product?.Actie;
    const stockStatus = stock?.stockLevelStatus;
    const productStrikeThroughPrice = (Prijs?.strikeThroughPrice || strikeThroughPrice);
    const oldPrice = (Prijs !== null ? Prijs?.oldPrice : null) || productStrikeThroughPrice;

    const track = (product) => {
        try {
            if (!product.__queryID) {
                return false;
            }
    
            aa('clickedObjectIDsAfterSearch', {
                userToken: 'user-' + user,
                eventName: 'PLP: Product Clicked',
                index: algoliaIndex + '-product-' + storeCode,
                queryID: product.__queryID,
                objectIDs: [product.objectID],
                positions: [product.__position],
            });
        } catch (error) {
            //
        }

        try {

            const { code, Merk, price } = product;

            ecommerceEvent('select_item', {
                //item_list_id
                //item_list_name
                items:[{
                    item_id: code.includes("variant-") ? code : "variant-" + code,
                    item_name: name,
                    item_brand: Merk ?? product?.manufacturer,
                    price: productPrice ?? price?.value
                }]
            });
           
        
           
        } catch (error) {
            //
        }
    }

    return (
        <>
            {code !== undefined && 
    
        <div className={classes.root}>

            <ProductLabels product={product} />
            <WishlistButton code={code} className={classes.wishlistbutton} />
            <Link
                to={`/${storeCode}/${prefix_product}/${baseCode}/${slug}`}
                state={{
                    code: baseCode,
                    name: name,
                    Merk: Merk,
                    descriptionHs: descriptionHs,
                    price: productPrice ?? price?.value,
                    priceRange: priceRange,
                    stock: stock,
                    images: images,
                    orderableAums: orderableAums,
                    trustPilotProductOneStar,
                    trustPilotProductTwoStars,
                    trustPilotProductThreeStars,
                    trustPilotProductFourStars,
                    trustPilotProductFiveStars,
                    trustPilotProductReviewAverage,
                    trustPilotProductReviewCount,
                    queryID: product?.__queryID
                }}
                onClick={() => track(product)}
                className={classes.link}
            >
                <div className={classes.image}>
                    <ProductImage
                        src={(images[0] ? images[0].url : '/images/placeholder.png')}
                        width={256}
                        height={256}
                        alt={name}
                        loading={((index !== null && index < 3) ? 'eager' : 'lazy')}
                    />
                </div>
                <div>{ (product?.Merk ?? product?.manufacturer) || <>&nbsp;</> }</div>
                <strong>{ name }</strong>
            </Link>

            {
                !hideReviews
                &&
                <div className={classes.reviews}>
                    {
                        (trustPilotProductReviewAverage > 0)
                        &&
                        <Average average={trustPilotProductReviewAverage} total={trustPilotProductReviewCount} className={classes.review_stars} />
                    }
                </div>
            }

            {
                productPrice
                &&
                <div className={classes.prices}>
                    <Price price={productPrice} currency={currency} locale={locale} className={classes.price} currencySymbol={false}/>
                    {
                        (oldPrice || product?.promotionFromPrice)
                        &&
                        <div className={classes.price_from_wrapper}>
                            {
                                !promotion
                                &&
                                <span className={classes.price_from_label}>{ t('productdetails.info.msrp') }</span>
                            }
                            <Price price={product?.promotionFromPrice ?? oldPrice} currency={currency} locale={locale} className={classes.price_from} currencySymbol={false}/>
                        </div>
                    }
                </div>

                || price?.value && 

                <div className={classes.prices}>
                    <Price price={price?.value} currency={currency} locale={locale} className={classes.price} currencySymbol={false} />
                </div>
            }

            {
                stockStatus !== 'outOfStock'
                    ?
                    <div className={classes.in_stock}>
                        <CheckRoundIcon size={16} title="op-voorraad" /> { t('productdetails.info.in_stock') }
                    </div>
                    :
                    <div className={classes.out_stock}>
                        { t('productdetails.info.out_off_stock') }
                    </div>
            }

            {
                stockStatus !== 'outOfStock' && !hideAddToCart
                &&
                <div className={classes.atc}>
                    <AddToCartSmall onClick={() => setModalOpen(true)} />
                </div>
            }

            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <AddToCart product={product} /*unit={packagingUnit}*/ />
            </Modal>

        </div>

            }
            
        </>
    );
}

export default ProductTile;